import Cookies from "js-cookie";
import { setUserData } from "../state/reducers/userSlice";
import store from "../state/store";
import {
  setAdminBuySellTab,
  setAdminDashboardTab,
  setAdminUserTab,
  setBuySellActiveTab,
  setMyBidsBuySellTab,
  setMyBidsTab,
  setAdminDirectoryTab,
} from "../state/reducers/applicationSlice";

export const setAuthTokenCookie = (token) => {
  Cookies.set("auth-token", token);
};

export const getAuthTokenCookie = () => {
  return Cookies.get("auth-token");
};

export const removeAuthTokenCookie = () => {
  Cookies.remove("auth-token");
  localStorage?.removeItem("userData");
  localStorage?.removeItem("vendorData");
  localStorage?.removeItem("profile");
  localStorage?.removeItem("buySellActiveTab");
  localStorage?.removeItem("myBidsBuySellTab");
  localStorage?.removeItem("myBidsProjectsTab");
  localStorage?.removeItem("adminBuySellTab");
  localStorage?.removeItem("adminUserTab");
  localStorage?.removeItem("adminDashboardTab");
  localStorage?.removeItem("projectsActiveTab");
  localStorage?.removeItem("adminDirectoryTab");
  store.dispatch(setUserData(null));
  store.dispatch(setBuySellActiveTab(0));
  store.dispatch(setMyBidsTab(1));
  store.dispatch(setAdminBuySellTab(0));
  store.dispatch(setAdminUserTab(0));
  store.dispatch(setAdminDashboardTab(0));
  store.dispatch(setMyBidsBuySellTab({ value: 0, label: "Current bids" }));
  store.dispatch(setAdminDirectoryTab(1));
};

export const setLoginTokenCookie = (token) => {
  Cookies.set("login-token", token);
};

export const getLoginTokenCookie = () => {
  return Cookies.get("login-token");
};

export const removeLoginTokenCookie = () => {
  Cookies.remove("login-token");
};
