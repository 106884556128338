import { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import masterDataService from "../services/masterDataService";
import Tooltip from "@mui/material/Tooltip";

const GooglePlaceAutocomplete = ({
  name,
  label,
  required,
  placeholder,
  disabled,
  errors = () => "",
  setError = () => "",
  clearErrors = () => "",
  setValue = () => "",
  register = () => "",
  watch = () => "",
  getCoordinates = () => "",
  setCountryCode = () => "",
  desc = "",
  pos = "bottom",
  delay = 1000,
}) => {
  const baseClass =
    "text-sm placeholder:text-sm placeholder:text-gray-100 appearance-none rounded-full block w-full text-black-100 border border-gray-300 py-2 px-4 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed";
  const errorClass = errors?.[name] ? "border-red-600" : "focus:border-primary";
  const [countryList, setCountryList] = useState([]);
  const handleChange = (value) => {
    setValue(name, value);
    setError(name, {
      type: "server",
      message: `Field is Required.`,
    });
  };
  const getCountryList = async () => {
    try {
      let response = await masterDataService.getCountryList();
      let data = [];
      response.data.data.forEach((ele) => {
        data.push({ label: ele.name, value: ele.id });
      });
      setCountryList(data);
    } catch (error) {
      console.log("Error fetching country list", error);
    }
  };

  const handleSelectAddress = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    let countryName = results[0]?.address_components?.filter((val) => {
      return val?.types?.includes("country");
    });
    let countryCode = countryList?.filter((val) => {
      return val?.label?.toLowerCase() === countryName[0]?.long_name?.toLowerCase();
    });
    setCountryCode(countryCode[0]?.value);
    getCoordinates(latLng);
    setValue(name, value);
    clearErrors(name);
  };

  useEffect(() => {
    setValue(name, watch(name) || "");
  }, [name, setValue, watch]);
  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <div>
      {label && (
        <label className="block tracking-wide text-black-100 text-xs font-normal mb-2" htmlFor="input-field">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <PlacesAutocomplete value={watch(name) || ""} onChange={handleChange} onSelect={handleSelectAddress}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3 relative">
              <Tooltip title={desc} placement={pos} enterDelay={delay}>
                <input
                  {...register(name)}
                  {...getInputProps({
                    className: `${baseClass} ${errorClass}`,
                  })}
                  autoComplete="none"
                  disabled={disabled ? true : false}
                  className={`${baseClass} ${errorClass}`}
                  placeholder={placeholder}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </Tooltip>

              {suggestions.length > 0 && (
                <ul className="mt-2 border border-gray-300 shadow-md rounded-xl rounded-bl-xl rounded-br-xl py-1 absolute mx-[2%] left-0 top-full w-[96%] z-50 bg-white">
                  {suggestions.map((suggestion, key) => (
                    <li
                      {...getSuggestionItemProps(suggestion)}
                      key={key}
                      className="text-sm font-normal text-black-900 px-3 py-2 cursor-pointer hover:bg-[#f6f6f6]"
                    >
                      {suggestion?.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {errors?.[name] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[name]?.message}</span>}
    </div>
  );
};

export default GooglePlaceAutocomplete;
