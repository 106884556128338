import { Controller } from "react-hook-form";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";

const CustomSelect = ({
  label,
  defaultValue,
  options,
  required,
  name,
  SideText,
  errors,
  placeholder,
  disabled = false,
  control,
  desc = "",
  pos = "bottom",
  delay = 1000,
}) => {
  return (
    <div>
      {label && (
        <>
          <label className="block tracking-wide text-black-100 text-xs font-normal mb-2" htmlFor="input-field">
            {label}
            {required && <span className="text-red-500">*</span>}
          </label>
          <Tooltip title={desc} placement={pos} enterDelay={delay}>
            <div className="flex relative">
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={disabled}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={defaultValue}
                    options={options}
                    placeholder={placeholder}
                    theme={(theme) => ({
                      ...theme,
                      height: 160,
                      colors: {
                        ...theme.colors,
                        primary25: "#f6f6f6",
                        primary: "#325B22",
                      },
                    })}
                    className="text-sm text-black-100 w-full"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 100,
                        height: 36,
                        minHeight: 36,
                        boxShadow: "none",
                        borderColor: errors?.[name] ? "rgb(220 38 38)" : state.isFocused ? "#325b22" : "#c1c1c1",
                        "&:hover": {
                          borderColor: "",
                        },
                        // border:  ? "primary" : "#D9D9D9",
                      }),
                      option: (base) => ({
                        ...base,
                        borderBottom: `1px solid #D9D9D9`,
                        height: "100%",
                      }),
                    }}
                  />
                )}
              />
              {SideText && <span className="absolute right-12 top-2 text-sm font-normal text-black-900">{SideText}</span>}
            </div>
          </Tooltip>

          {errors?.[name] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[name]?.message}</span>}
        </>
      )}
    </div>
  );
};

export default CustomSelect;
