import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const storedProfile = localStorage.getItem("profile");
  const profile = storedProfile ? storedProfile === "true" : true;

  const storedBuySellActiveTab = localStorage.getItem("buySellActiveTab");
  const buySellActiveTab = storedBuySellActiveTab ? parseInt(storedBuySellActiveTab) : 0;

  const storedAdminBuySellTab = localStorage.getItem("adminBuySellTab");
  const adminBuySellTab = storedAdminBuySellTab ? parseInt(storedAdminBuySellTab) : 0;

  const storedAdminUserTab = localStorage.getItem("adminUserTab");
  const adminUserTab = storedAdminUserTab ? parseInt(storedAdminUserTab) : 0;

  const storedMyBidsTab = localStorage.getItem("myBidsTab");
  const myBidsTab = storedMyBidsTab ? JSON.parse(storedMyBidsTab) : 1;

  const storedMyBidsBuySellTab = localStorage.getItem("myBidsBuySellTab");
  const myBidsBuySellTab = storedMyBidsBuySellTab ? JSON.parse(storedMyBidsBuySellTab) : { value: 0, label: "Current bids" };

  const storedMyBidsProjectsTab = localStorage.getItem("myBidsProjectsTab");
  const myBidsProjectsTab = storedMyBidsProjectsTab ? JSON.parse(storedMyBidsProjectsTab) : { value: 0, label: "Current bids" };

  const storedAdminDashboardTab = localStorage.getItem("adminDashboardTab");
  const adminDashboardTab = storedAdminDashboardTab ? parseInt(storedAdminDashboardTab) : 0;

  const storedProjectsActiveTab = localStorage.getItem("projectsActiveTab");
  const projectsActiveTab = storedProjectsActiveTab ? parseInt(storedProjectsActiveTab) : 0;

  const storedAdminDirectoryTab = localStorage.getItem("adminDirectoryTab");
  const adminDirectoryTab = storedAdminDirectoryTab ? parseInt(storedAdminDirectoryTab) : 0;
  return {
    spinner: 0,
    language: "en",
    profile,
    buySellActiveTab,
    myBidsBuySellTab,
    myBidsProjectsTab,
    adminBuySellTab,
    adminUserTab,
    adminDashboardTab,
    myBidsTab,
    projectsActiveTab,
    adminDirectoryTab,
  };
};

const applicationSlice = createSlice({
  name: "application",
  initialState: getInitialState(),
  reducers: {
    setSpinner: (state, action) => {
      state.spinner = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
      localStorage.setItem("profile", action.payload ? "true" : "false");
    },
    setBuySellActiveTab: (state, action) => {
      state.buySellActiveTab = action.payload;
      localStorage.setItem("buySellActiveTab", action.payload.toString());
    },
    setMyBidsBuySellTab: (state, action) => {
      state.myBidsBuySellTab = action.payload;
      localStorage.setItem("myBidsBuySellTab", JSON.stringify(action.payload));
    },
    setMyBidsProjectsTab: (state, action) => {
      state.myBidsProjectsTab = action.payload;
      localStorage.setItem("myBidsProjectsTab", JSON.stringify(action.payload));
    },
    setAdminBuySellTab: (state, action) => {
      state.adminBuySellTab = action.payload;
      localStorage.setItem("adminBuySellTab", action.payload.toString());
    },
    setAdminUserTab: (state, action) => {
      state.adminUserTab = action.payload;
      localStorage.setItem("adminUserTab", action.payload.toString());
    },
    setAdminDashboardTab: (state, action) => {
      state.adminDashboardTab = action.payload;
      localStorage.setItem("adminDashboardTab", action.payload.toString());
    },
    setMyBidsTab: (state, action) => {
      state.myBidsTab = action.payload;
      localStorage.setItem("myBidsTab", action.payload.toString());
    },
    setProjectsActiveTab: (state, action) => {
      state.projectsActiveTab = action.payload;
      localStorage.setItem("projectsActiveTab", action.payload.toString());
    },
    setAdminDirectoryTab: (state, action) => {
      state.adminDirectoryTab = action.payload;
      localStorage.setItem("adminDirectoryTab", action.payload.toString());
    },
  },
});

export const {
  setSpinner,
  setLanguage,
  setProfile,
  setBuySellActiveTab,
  setMyBidsBuySellTab,
  setMyBidsProjectsTab,
  setMyBidsTab,
  setAdminBuySellTab,
  setAdminDashboardTab,
  setAdminUserTab,
  setAdminDirectoryTab,
  setProjectsActiveTab,
} = applicationSlice.actions;
export default applicationSlice.reducer;
