import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const masterDataService = {
  getUserStatus: () => {
    return http.get(URLS.masterData.auth.getUserStatus);
  },
  getUserRoles: () => {
    return http.get(URLS.masterData.auth.getUserRoles);
  },
  getDocTypes: () => {
    return http.get(URLS.masterData.auth.getDocTypes);
  },
  getDocSubTypes: (data) => {
    return http.post(URLS.masterData.auth.getDocSubTypes, data);
  },
  getPropertyStatus: () => {
    return http.get(URLS.masterData.property.getPropertyStatus);
  },
  getPropertyTypes: () => {
    return http.get(URLS.masterData.property.getPropertyTypes);
  },
  getIndustryTypes: () => {
    return http.get(URLS.masterData.property.getIndustryTypes);
  },
  getBuildingYears: () => {
    return http.get(URLS.masterData.property.getBuildingYears);
  },
  getOccupantsNumbers: () => {
    return http.get(URLS.masterData.property.getOccupantsNumbers);
  },
  getRoofAreaUnits: () => {
    return http.get(URLS.masterData.property.getRoofAreaUnits);
  },
  getRoofLengthUnits: () => {
    return http.get(URLS.masterData.property.getRoofLengthUnits);
  },
  getRoofBreadthUnits: () => {
    return http.get(URLS.masterData.property.getRoofBreadthUnits);
  },
  getCountryList: () => {
    return http.get(URLS.masterData.auth.getCountryList);
  },
  getProjectTypes: () => {
    return http.get(URLS.masterData.project.getProjectTypes);
  },
  getProjectCategories: () => {
    return http.get(URLS.masterData.project.getProjectCategories);
  },
  getPropertyMasterList: () => {
    return http.get(URLS.masterData.project.getPropertyMasterList);
  },
  getAgreementTypes: () => {
    return http.get(URLS.masterData.buySell.getAgreementTypes);
  },
  getCapacityUnits: () => {
    return http.get(URLS.masterData.buySell.getCapacityUnits);
  },
  getCurrencyList: () => {
    return http.get(URLS.masterData.buySell.getCurrencyList);
  },
  getModuleSpecification: () => {
    return http.get(URLS.masterData.buySell.getModuleSpecification);
  },
  getGridConnections: () => {
    return http.get(URLS.masterData.buySell.getGridConnections);
  },
  getVendorBidUnits: () => {
    return http.get(URLS.masterData.vendorKyc.getVendorBidUnits);
  },
  getNationalities: () => {
    return http.get(URLS.masterData.vendorKyc.getNationalities);
  },
  getRejectReasons: () => {
    return http.get(URLS.masterData.admin.buySell.getRejectReasons);
  },
  getUserRejectReasons: () => {
    return http.get(URLS.masterData.admin.userManagement.getUserRejectReasons);
  },
  getCountryWithNationality: () => {
    return http.get(URLS.masterData.auth.getCountryWithNationality);
  },
  getPropertyType: () => {
    return http.get(URLS.masterData.projects.getPropertyType);
  },
  getProjectStatus: () => {
    return http.get(URLS.masterData.admin.projects.projectStatus);
  },
  getProjectSubStatus: () => {
    return http.get(URLS.masterData.admin.projects.projectSubStatus);
  },
  getCostSharingUnits: () => {
    return http.get(URLS.masterData.buySell.getCostSharingUnits);
  },
  getDisputeReasons: () => {
    return http.get(URLS.masterData.projects.getDisputeReasons);
  },
  getProjectRejectReasons: () => {
    return http.get(URLS.masterData.projects.getProjectRejectReasons);
  },
  getProjectModels: () => {
    return http.get(URLS.masterData.projects.getProjectModels);
  },
  getProjectBidStatus: () => {
    return http.get(URLS.masterData.projects.getProjectBidStatus);
  },
  getBidProjectStatus: () => {
    return http.get(URLS.masterData.projects.getBidProjectStatus);
  },
  getCompanyCategories: () => {
    return http.get(URLS.masterData.directory.getCompanyCategories);
  },
};

export default masterDataService;
